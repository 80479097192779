import { postRequest, getRequest } from './index'
// 采购合同分页
export const agentPurchaseContractFundsPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/fundsPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 采购合同管理详情
export const agentPurchaseContractFundsDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentPurchaseContract/fundsDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
